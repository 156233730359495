import React, { useState, useEffect } from "react";
import {
  RainbowKitProvider,
  ConnectButton,
  getDefaultWallets,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, useAccount, useSignMessage, createConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { configureChains } from 'wagmi';
import "./App.css";

// Configure chains and providers
const { chains, publicClient } = configureChains(
  [mainnet, polygon, optimism, arbitrum],
  [publicProvider()]
);

// Get default wallets
const defaultWallets = getDefaultWallets({
  appName: 'Oura Registration',
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  chains,
});

// Log the structure of defaultWallets
console.log("Default Wallets:", defaultWallets);
console.log("Connectors:", defaultWallets.connectors);

// Ensure connectors is an array
const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: defaultWallets.wallets[0].wallets,
  },
]);

// Create wagmi config
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function AppContent() {
  const [pac, setPac] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [nickname, setNickname] = useState("");
  const [status, setStatus] = useState("");
  const [signature, setSignature] = useState("");
  const { address, isConnected } = useAccount();
  const { signMessage } = useSignMessage({
    onSuccess(signature) {
      setSignature(signature);
      setStatus(`Message signed successfully for: ${address}`);
    },
    onError(error) {
      console.error("Message signing failed:", error);
      setStatus("Message signing failed.");
    },
  });

  // Automatically trigger signing when wallet connects
  useEffect(() => {
    if (isConnected && address && !signature) {
      const message = "You are signing this message to prove ownership of your wallet. No transaction or gas fees will occur.";
      signMessage({ message });
    }
  }, [isConnected, address, signature, signMessage]);

  const submitPac = async () => {
    if (!isConnected) {
      alert("Please connect your wallet first.");
      return;
    }
    if (!pac) {
      alert("Please enter your Personal Access Code.");
      return;
    }
    if (!signature) {
      alert("Wallet signature missing. Please reconnect your wallet.");
      return;
    }

    try {
      const response = await fetch("https://api.v2.walletchat.fun/oura_register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet: address,
          pac: pac,
          signature: signature,
          referralcode: referralCode,
          nickname: nickname,
        }),
      });

      if (response.ok) {
        setStatus("PAC successfully registered!");
        setTimeout(() => {
          window.location.href = "https://leaderboard.intra.so";
        }, 2000);
      } else {
        setStatus("Error: Could not register PAC.");
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setStatus("Network error: Could not register PAC.");
      console.error("Network error:", error);
    }
  };

  return (
    <div className="App">
      <div className="container">
        <h2>Oura Ring Data Registration</h2>
        <p>Enter your Oura Ring Personal Access Code to register your data with your wallet.</p>
        <ConnectButton showBalance={false} chainStatus="none" />
        <input
          className="pac-input"
          type="text"
          value={pac}
          onChange={(e) => setPac(e.target.value)}
          placeholder="Enter Personal Access Code"
        />
        <input
          className="referral-input"
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          placeholder="Enter Referral Code (optional)"
        />
        <input
          className="nickname-input"
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          placeholder="Enter Nickname (optional)"
        />
        <button onClick={submitPac}>Submit</button>
        <p>{status}</p>
        <p>
          <span className="tooltip">
            Your Oura Personal Access Code 
            <span className="tooltiptext">
              To get started with these personal access tokens, follow the steps below:<br />
              1. Navigate to the Personal Access Tokens page (see link in (PAC))<br />
              2. In the upper-right corner of the page click the "Create A New Personal Access Token"<br />
              3. Enter a unique note for the new Personal Access Token you are about to generate<br />
              4. Click "Create Personal Access Token" to submit the form and create your new personal access token<br />
              5. You should now have a new access token listed on the Personal Access Tokens page.
            </span>
          </span>
           &nbsp;<a href="https://support.ouraring.com/hc/en-us/articles/4415266939155-The-Oura-API#h_01H5B94SP4P9YHG9ZKN1H69E7Z" target="_blank" rel="noopener noreferrer">(PAC)</a>  gives access to all your Oura Ring data, 
          however no tags are obtained. To see what data is collected, please check our 
          open-source code <a href="https://github.com/Wallet-Chat/walletchatAPI/blob/a4ca0dfb793faede9e4d012682266781eaea9c1c/controllers/chatcontrollers.go#L4704" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      </div>
    </div>
  );
}

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <AppContent />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
